import React, { useEffect } from "react"
import { getCurrentUser } from "../actions/authActions"
import { connect } from "react-redux"
import SignInForm from "./signInForm"
import Loader from "../components/loader"
import { navigate } from "gatsby-link"
import SEO from "../components/seo"

const IndexPage = (props) => {
  const { user, loading } = props

  useEffect(() => {
    user && props.getCurrentUser(user) && navigate("/app")
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <SEO title="Log In" />
      {loading && <Loader />}
      {
        <SignInForm />
      }
    </div>
  )
}

const mapStateToProps = ({ userReducer: { user, loading } }) => ({ user, loading })
export default connect(mapStateToProps,
  { getCurrentUser }
)(IndexPage);